<template>
    <div class="article" v-html="content">
    </div>
</template>
  
  <script>
  export default {
    data() {
      return {
        content: "",//内容展示
      };
    },
    mounted() {
         var id =  this.$route.params.id;
        fetch("https://www.huabanche.club/privacy_ad/admin/privacy_info_get?id=" + id)
          .then((res) => res.json())
          .then((res) => {
            this.content = fillTableContainer(res.data.content);
            document.title = res.data.title;
          });

        /** 给表格填充容器 */
        function fillTableContainer(htmlString, containerClassName = "table-container") {
          var newHtml = htmlString.replace(/<table/g, `<div class="${containerClassName}"><table`);

          return newHtml.replace(/<\/table>/g, "</table></div>");
        }
    },
  };
  </script>
  <style lang="less" src="./privacyPolicyNew.less"></style>
  